import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeCover from "../components/home/home-cover"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
    const { backgroundImage } = data.wpPage.home_page
    return (
        <Layout noMargin>
            <SEO title="Room to breathe" />
            <HomeCover cover={backgroundImage.sourceUrl} />
        </Layout>
    )
}

export default IndexPage

export const HomeQuery = graphql`
    query homeQueru {
        wpPage(title: { eq: "Home" }) {
            home_page {
                backgroundImage {
                    sourceUrl
                }
            }
        }
    }
`
