import React, { useState } from "react"
import styled from "styled-components"
// import CovidPolicy from "./covid-policy"
import "./index.css"

const Container = styled.div`
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    background: white;
`
const Cover = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
`

const HomeCover = ({ cover }) => {
    // const [showingPolicy, setShowingPolicy] = useState(true)
    return (
        <Container align="center" justify="center">
            <Cover src={cover} />
            {/* {showingPolicy && (
                <CovidPolicy showing={setShowingPolicy} covidData={covidData} />
            )} */}
        </Container>
    )
}
export default HomeCover
